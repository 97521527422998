import React, { ReactNode, memo, useEffect, useRef, useState } from "react";
import "../assets/scss/layouts/ResponsiveLayout.scss";
import { WeatherIndicator } from "../components/WeatherIndicator";
import { ReactComponent as IfpenWhiteLogo } from "../assets/img/IfpenWhiteLogo.svg";
import AppBar from "../components/AppBar";
import { Box, Flex, Hide, Link, Show, Text } from "@chakra-ui/react";

interface ResponsiveLayoutProps {
  leftSide?: ReactNode;
  rightSide?: ReactNode;
  header?: ReactNode;
  children?: ReactNode;
}

const ResponsiveLayoutComponent: React.FC<ResponsiveLayoutProps> = ({
  leftSide,
  rightSide,
  header,
  children,
}) => {
  return (
    <Flex
      direction="column"
      minH="100vh"
      className="bg-gray-100"
      bgGradient="linear(to-b, blue.500, white)"
    >
      {/* Header (Fixe) */}
      <Box as="header" position="fixed" top="0" left="0" w="100%" zIndex="1000">
        <Flex align="center" justify="space-between" pr={2} py={2} gap={2}>
          <WeatherIndicator />
          <Flex>
            <IfpenWhiteLogo />
          </Flex>
        </Flex>
      </Box>

      {/* Main Content (Défilable) */}
      <Box
        as="main"
        flex="1"
        mt="5rem" // Compense la hauteur du header
        pb="5rem" // Compense la hauteur du footer
        overflowY="auto"
        w={"100%"}
      >
          {leftSide && rightSide && (
            <Flex
              flex="1"
              px={2}
              gap={2}
              flexDirection={"row"}
              justifyContent={"space-between"}
              maxH="calc(100vh - 10rem)" // Pour éviter de déborder
              w={"100%"}
            >
              {/* Colonne de gauche : Contenu principal */}
              <Box flex="1" className="p-4 max-w-fit" overflow={"auto"}>
                {leftSide}
              </Box>

              {/* Colonne de droite : Composant personnalisé */}
              <Flex
                flex="2"
                flexDirection={"column"}
                className="p-4 overflow-y-auto"
                gap={2}
              >
                {rightSide}
              </Flex>
            </Flex>
          )} 
        {children}
      </Box>

      {/* Footer (Fixe) */}
      <Box
        as="footer"
        position="fixed"
        bottom="0"
        left="0"
        w="100%"
        zIndex="1000"
      >
        <Flex justify="center" align="center">
          <AppBar />
        </Flex>
      </Box>
    </Flex>
  );
};

export const ResponsiveLayout = memo(ResponsiveLayoutComponent);
