import { COLORS, formatter } from "../utils/toolbox";
import Panel from "./Panel";
import { Switcher } from "./Switcher";
import { DoughnutGraph } from "./DoughnutGraph";
import { LabelWithValue } from "./LabelWithValue";
import React, { memo } from "react";

interface HomePageIndicatorProps {
  title: string;
  link: string;
  unit: string;
  globalValue: string;
  graphData: DoghnutData;
  energyDistribution: EnergyDistribution[];
}
interface DoghnutData {
  backgroundColor: string[];
  labels: string[];
  datas: number[] | undefined;
}
interface EnergyDistribution {
  value: string;
  color: COLORS;
  label: string;
}
const HomePageIndicatorComponent : React.FC<HomePageIndicatorProps> =  ({ title, link, unit, globalValue, graphData, energyDistribution }) => {
  return (
    <Panel className="w-full md:max-w-fit" title={title} link={link}>
      <Switcher>
        <div className="flex flex-col ">
          <p className="text-center text-xl text-[#0488F9] my-4 font-semibold">
            <span>{globalValue}</span>
          </p>
          <DoughnutGraph
            formatter={formatter(unit)}
            backgroundColor={graphData.backgroundColor}
            labels={graphData.labels}
            datas={graphData.datas}
          />
          {energyDistribution.map((elt, index) => {
            return <LabelWithValue key={index} {...elt} />;
          })}
        </div>
      </Switcher>
    </Panel>
  );
}
export const HomePageIndicator = memo(HomePageIndicatorComponent)