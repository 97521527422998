import React, { useEffect, useState } from "react";
import "../assets/scss/pages/WeatherPage.scss";
import { WeatherDetails } from "../components/WeatherDetails";
import { useLazyGetHomeBalanceDataQuery } from "../services/ems.service";
import { displayUnit, useSwitcherContext } from "../contexts/SwitcherContext";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { symbolMap } from "../utils/toolbox";
import { Flex, Show, Skeleton } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { ReactComponent as Sun } from "../assets/icons/ifpen-sun.svg";
import { ReactComponent as Pylon } from "../assets/icons/ifpen-pylon.svg";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import { COLORS } from "../utils/toolbox";
import { Indicator } from "../components/Indicator";
import { ResponsiveLayout } from "../layouts/ResponsiveLayout";
interface BalanceIndicatorItem {
  value: string;
  description: string;
  color: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}
export const WeatherPage: React.FC = () => {
  const [homeBalanceData, setHomeBalanceData] = useState();
  const [
    getHomeBalanceData,
    { isLoading: homeBalanceLoading, isError: homeBalanceIsError },
  ] = useLazyGetHomeBalanceDataQuery();
  const { unit, period, switcherDate, setSwitcherDate } = useSwitcherContext();
  const topologyId = getSelectedTopo(store.getState()).id;
  const navigate = useNavigate();
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);
  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());
    getHomeBalanceData({ date: new Date(), topologyId })
      .unwrap()
      .then((res) => {
        setHomeBalanceData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [switcherDate, topologyId]);

  const balanceIndicatorItems: BalanceIndicatorItem[] = [
    {
      value:
        (homeBalanceData &&
          period in homeBalanceData &&
          "prod" + unit in homeBalanceData[period] &&
          "solar" in homeBalanceData[period]["prod" + unit] &&
          (homeBalanceData[period]["prod" + unit]["solar"] as number).toFixed(
            1
          ) + displayUnit(unit, symbolMap)) ||
        "",
      description: "Production solaire potentielle",
      icon: Sun,
      color: COLORS.PROD,
    },
    {
      value:
        (homeBalanceData &&
          period in homeBalanceData &&
          "prod" + unit in homeBalanceData[period] &&
          "battery" in homeBalanceData[period]["prod" + unit] &&
          (homeBalanceData[period]["prod" + unit]["battery"] as number).toFixed(
            1
          ) + displayUnit(unit, symbolMap)) ||
        "",
      description: "Capacité de charge batterie",
      icon: BatteryUp,
      color: COLORS.BATTERYUP,
    },
    {
      value:
        (homeBalanceData &&
          period in homeBalanceData &&
          "prod" + unit in homeBalanceData[period] &&
          "provider" in homeBalanceData[period]["prod" + unit] &&
          (
            homeBalanceData[period]["prod" + unit]["provider"] as number
          ).toFixed(1) + displayUnit(unit, symbolMap)) ||
        "",
      description: "Surplus Potentiel",
      icon: Pylon,
      color: COLORS.SURPLUS,
    },
  ];
  return (
    <>
      <Show below="md">
        <ResponsiveLayout>
          <Flex
            flex="2"
            py={2}
            px={2}
            flexDirection={"column"}
            className="overflow-y-auto"
            gap={2}
          >
            <div className="flex flex-col w-full">
              {homeBalanceIsError ? (
                <div>Error</div>
              ) : (
                <div className="flex flex-row gap-2 md:gap-4 justify-between py-2">
                  {balanceIndicatorItems.map((item, index) => (
                    <Skeleton
                      key={index}
                      isLoaded={!homeBalanceLoading}
                      className="w-full h-full py-2"
                    >
                      <Indicator
                        key={index}
                        icon={item.icon}
                        color={item.color}
                        value={item.value}
                        description={item.description}
                      />
                    </Skeleton>
                  ))}
                </div>
              )}
            </div>
            <div className="w-full flex flex-col gap-4">
              <WeatherDetails />
            </div>
          </Flex>
        </ResponsiveLayout>
      </Show>
      <Show above="md">
        <ResponsiveLayout>
          <Flex
            flex="2"
            py={2}
            px={2}
            flexDirection={"row"}
            className="overflow-y-auto"
            gap={2}
            maxH="calc(100vh - 10rem)" // Pour éviter de déborder
            w={"100%"}
          >
            <div className="w-full flex-1 flex flex-col gap-4 list">
              <WeatherDetails />
            </div>
            <div className="flex-1 flex flex-col w-full list">
              {homeBalanceIsError ? (
                <div>Error</div>
              ) : (
                <div className="flex flex-row gap-2 md:gap-4 justify-between py-2">
                  {balanceIndicatorItems.map((item, index) => (
                    <Skeleton
                      key={index}
                      isLoaded={!homeBalanceLoading}
                      className="w-full h-full py-2"
                    >
                      <Indicator
                        key={index}
                        icon={item.icon}
                        color={item.color}
                        value={item.value}
                        description={item.description}
                      />
                    </Skeleton>
                  ))}
                </div>
              )}
            </div>
          </Flex>
        </ResponsiveLayout>
      </Show>
    </>
  );
};
