/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react"; 
import "../assets/scss/components/AutonomieIndicator.scss";
import { formatDate, symbolMap } from "../utils/toolbox";
import { displayUnit } from "../contexts/SwitcherContext";
interface AutonomieIndicatorProps {
  data: any;
  autonomieValue?: string;
  avantinstallation?: string;
  avecInstallation?: string;
  gain?: string;
  period: string;
  date: Date;
  unit: string;
}

export const AutonomieIndicator: React.FC<AutonomieIndicatorProps> = ({
  data,
  period,
  date,
  unit,
}) => {
  const [autonomieValuekWh, setAutonomieValuekWh] = useState<
    string | undefined
  >();
  const [autonomieValuePct, setAutonomieValuePct] = useState<
    string | undefined
  >();

  const [avantinstallation, setAvantinstallation] = useState<
    string | undefined
  >();
  const [avecInstallation, setAvecInstallation] = useState<
    string | undefined
  >();
  const [gain, setGain] = useState<string | undefined>();
  const [bawData, setBaw] = useState<
    { best: number; worst: number } | undefined | null
  >();
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if(data && period in data){
      setAutonomieValuekWh(data[period]["myAutonomy" + "kWh"].toFixed(1));
      setAutonomieValuePct(data[period]["myAutonomy" + "Pct"].toFixed(1));
      setAvantinstallation(
        data[period]["myAutonomieBeforeInstallation" + unit].toFixed(1) +
          displayUnit(unit, symbolMap)
      );
      setAvecInstallation(
        data[period]["myAutonomieWithInstallation" + unit].toFixed(1) +
          displayUnit(unit, symbolMap)
      );
      setGain(
        data[period]["myGain" + "Euro"].toFixed(1) +
          displayUnit("Euro", symbolMap)
      );
      setBaw(data[period]["bawData"]);
      if (data[period]["bawData"]?.worst && data[period]["bawData"]?.best) {
        const max= data[period]["bawData"]?.best * 100
        const min = data[period]["bawData"]?.worst * 100
        if(min==max){
          setWidth(50)
        }else {
          if(Number(data[period]["myAutonomy" + "Pct"].toFixed(1))<min){
            setWidth(50)
          }else {
            const pct = ((Number(data[period]["myAutonomy" + "Pct"].toFixed(1))-min)/(max-min))*100
            setWidth(
              Math.floor(pct)
            );
          }
        }
       
      }
    }
    
  }, [data, period, unit]);

  return (
    <div className="graph-autonomie">
      <div className="graph-autonomie__pourcentage ">
        <div>{autonomieValuekWh} {displayUnit("kWh", symbolMap)}</div>
        <h1 className="text-sm">d&apos;autonomie</h1>
        {autonomieValuePct} %
      </div>
      <div className="graph-autonomie__detail-pourcentage ">
        <div className="graph-autonomie__detail-pourcentage__pourcentage-min  ">
          {bawData?.worst && Math.round(bawData?.worst * 100)}%
        </div>
        <div className="relative w-full h-6 rounded-full overflow-hidden border my-auto mx-2 border">
          <Box
            bgGradient="linear(to-r, red.500,yellow.300, yellow.300, green.300)"
            className="absolute top-0 left-0 h-full bg-green-500 w-full"
          ></Box>
          <div
            className={`absolute top-0  max-w-fit w-full h-full flex items-center justify-center text-black font-bold`}
          style={{
            left : `${width}%`,
            border:"2px solid black"
          }}
          >
          </div>
        </div>
        <div className="graph-autonomie__detail-pourcentage__pourcentage-max  ">
          {bawData?.best && Math.round(bawData?.best * 100)}%
        </div>
      </div>
      <div className="graph-autonomie__titre-taux-equipement-equivalent ">
        TAUX D&apos;EQUIPEMENT EQUIVALENT
      </div>
      <div className="graph-autonomie__facture">
        <div className="graph-autonomie__facture-sans-installation">
          <div className="graph-autonomie__facture-sans-installation__title ">
            SANS INSTALLATION
          </div>
          <div className="graph-autonomie__facture-sans-installation__value">
            {avantinstallation}
          </div>
        </div>
        <div className="graph-autonomie__facture-avec-installation ">
          <div className="graph-autonomie__facture-avec-installation__title">
            AVEC INSTALLATION
          </div>
          <div className="graph-autonomie__facture-avec-installation__value">
            {avecInstallation}
          </div>
        </div>
      </div>

      <div className="graph-autonomie__bilan">
        <div>
          <div className="graph-autonomie__bilan__date">
            En {formatDate(date, period)}
          </div>
          <div className="graph-autonomie__bilan__description">
            MON INSTALLATION M&apos;A FAIT GAGNER :{" "}
          </div>
          <div className="graph-autonomie__bilan__value">{gain}</div>
        </div>
      </div>
    </div>
  );
};

export default AutonomieIndicator;
