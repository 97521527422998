import { ResponsiveLayout } from "../layouts/ResponsiveLayout";

//legendes images
import { ReactComponent as Sun } from "../assets/icons/ifpen-sun.svg";
import { ReactComponent as Pylon } from "../assets/icons/ifpen-pylon.svg";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down.svg";
import {
  COLORS,
  TimeLabels,
  getFormatedDatetime,
  getLoadingDataErrorByStatus,
} from "../utils/toolbox";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { useNavigate } from "react-router-dom";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import React, { useEffect, useState } from "react";
import {
  MeasuredData,
  MonitoringResponse,
  useGetAutonomieIndicatorDataQuery,
  useLazyGetManualMonitoringWithFilterDataQuery,
  useLazyGetMonitoringActualAndForecastDataQuery,
} from "../services/ems.service";
import moment from "moment";
import { Box, Flex, Show, Skeleton } from "@chakra-ui/react";
import EmsAlert from "../components/EmsAlert";
import { Switcher } from "../components/Switcher";
import Panel from "../components/Panel";
import AutonomieIndicator from "../components/AutonomieIndicator";
import LabeledBarChart from "../components/LabeledBarChart";
import "../assets/scss/pages/PageIndicator.scss";
export const PageIndicator: React.FC = () => {
  const labelsProd: TimeLabels[] = [];
  const labelsConsum: TimeLabels[] = [];
  const pProdConsByBat: string[] = [];
  const pProdSentToGrid: string[] = [];
  const pProdConsByConsumers: string[] = [];
  const pConsoFromProd: string[] = [];
  const pConsoFromBat: string[] = [];
  const pConsoFromGrid: string[] = [];

  const pProdConsByBatForecast: string[] = [];
  const pProdSentToGridForecast: string[] = [];
  const pProdConsByConsumersForecast: string[] = [];
  const pConsoFromProdForecast: string[] = [];
  const pConsoFromBatForecast: string[] = [];
  const pConsoFromGridForecast: string[] = [];

  let pProdGlobal: number = 0;
  let pConsoGlobal: number = 0;

  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();
  const navigate = useNavigate();
  const selectedTopoId = getSelectedTopo(store.getState()).id;
  useEffect(() => {
    if (selectedTopoId == "") {
      navigate("/redirect");
    }
  }, [selectedTopoId]);

  useEffect(() => {
    setSwitcherDate(new Date());
    setUnit("kWh");
    setPeriod("day");
  }, [selectedTopoId]);

  //const monitoringResponse =  useGetMonitoringDataQuery(switcherDate)
  const [
    getMonitoringWithForecast,
    {
      isError: monitoringWithForecastIsError,
      isLoading: monitoringWithForecastIsLoading,
    },
  ] = useLazyGetMonitoringActualAndForecastDataQuery();
  const {
    data: autonomieData,
    isError: autonomieIsError,
    error: autonomieError,
    isLoading: autonomieIsLoading,
  } = useGetAutonomieIndicatorDataQuery({
    switcherDate,
    topologyId: selectedTopoId,
  });

  const manualMonitoringResponse =
    useLazyGetManualMonitoringWithFilterDataQuery();
  const [data, setData] = useState<{
    outputDTOActual: MonitoringResponse;
    outputDTOForeacasted: MonitoringResponse;
  }>();
  const fetchData = async (date: Date, topoId: string) => {
    getMonitoringWithForecast({
      date: moment(date).format("YYYY-MM-DD"),
      topologyId: topoId,
    })
      .unwrap()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        if (err.status == 401) {
          navigate("/login");
        }
      });
  };
  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());
    fetchData(switcherDate, selectedTopoId);
  }, [switcherDate, selectedTopoId]);

  //Actual
  if (data && data.outputDTOActual && period in data.outputDTOActual) {
    const actual = data.outputDTOActual;
    const filterDayTime = (item: MeasuredData) =>
      actual[period].aggregation == "1h" ? item.dayTime : true;
    (actual[period]["pProdConsByBat" + unit] as MeasuredData[])
      .filter(filterDayTime)
      .forEach((item: MeasuredData) => {
        getFormatedDatetime(item, period, labelsProd);
        pProdConsByBat.push(item.energy.toFixed(1));
      });

    (actual[period]["pProdConsByConsumers" + unit] as MeasuredData[])
      .filter(filterDayTime)
      .forEach((item: MeasuredData) => {
        pProdConsByConsumers.push(item.energy.toFixed(1));
      });
    (actual[period]["pProdGlobal" + "kWh"] as MeasuredData[]).forEach(
      (item: MeasuredData) => {
        pProdGlobal += parseFloat(item.energy.toFixed(1));
      }
    );

    (actual[period]["pConsoGlobal" + "kWh"] as MeasuredData[]).forEach(
      (item: MeasuredData) => {
        pConsoGlobal += parseFloat(item.energy.toFixed(1));
      }
    );

    (actual[period]["pProdSentToGrid" + unit] as MeasuredData[])
      .filter(filterDayTime)
      .forEach((item: MeasuredData) => {
        pProdSentToGrid.push(item.energy.toFixed(1));
      });
    //conso
    (actual[period]["pConsoFromProd" + unit] as MeasuredData[]).forEach(
      (item: MeasuredData) => {
        getFormatedDatetime(item, period, labelsConsum);
        pConsoFromProd.push(item.energy.toFixed(1));
      }
    );

    (actual[period]["pConsoFromGrid" + unit] as MeasuredData[]).forEach(
      (item: MeasuredData) => {
        pConsoFromGrid.push(item.energy.toFixed(1));
      }
    );

    (actual[period]["pConsoFromBat" + unit] as MeasuredData[]).forEach(
      (item: MeasuredData) => {
        pConsoFromBat.push(item.energy.toFixed(1));
      }
    );
  }
  //forecast
  if (
    data &&
    data.outputDTOForeacasted &&
    period in data.outputDTOForeacasted
  ) {
    const forecasted = data.outputDTOForeacasted;
    const filterDayTime = (item: MeasuredData) =>
      forecasted[period].aggregation == "1h" ? item.dayTime : true;
    const addItem = (array: string[], item: MeasuredData) => {
      array.push(item.energy.toFixed(1));
    };
    (forecasted[period]["pProdConsByBat" + unit] as MeasuredData[])
      .filter(filterDayTime)
      .forEach((item: MeasuredData) => {
        getFormatedDatetime(item, period, labelsProd);
        addItem(pProdConsByBatForecast, item);
      });

    (forecasted[period]["pProdConsByConsumers" + unit] as MeasuredData[])
      .filter(filterDayTime)
      .forEach((item: MeasuredData) => {
        addItem(pProdConsByConsumersForecast, item);
      });

    (forecasted[period]["pProdSentToGrid" + unit] as MeasuredData[])
      .filter(filterDayTime)
      .forEach((item: MeasuredData) => {
        addItem(pProdSentToGridForecast, item);
      });
    //conso
    (forecasted[period]["pConsoFromProd" + unit] as MeasuredData[]).forEach(
      (item: MeasuredData) => {
        getFormatedDatetime(item, period, labelsConsum);
        addItem(pConsoFromProdForecast, item);
      }
    );

    (forecasted[period]["pConsoFromGrid" + unit] as MeasuredData[]).forEach(
      (item: MeasuredData) => {
        addItem(pConsoFromGridForecast, item);
      }
    );

    (forecasted[period]["pConsoFromBat" + unit] as MeasuredData[]).forEach(
      (item: MeasuredData) => {
        addItem(pConsoFromBatForecast, item);
      }
    );
  }
  const graphProd = {
    labels: labelsProd,
    datasets: [
      {
        key: 1,
        label: "Autoconsommation directe",
        data: pProdConsByConsumers,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0)",
        borderWidth: 0,
      },
      {
        key: 1,
        label: "Prédiction autoconsommation directe",
        data: pProdConsByConsumersForecast,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0,0.5)",
        borderWidth: 0,
        isForecast: true,
      },
      {
        key: 2,
        label: "Charge batterie",
        image: BatteryUp,
        darkImage: BatteryUp,
        data: pProdConsByBat,
        bgColor: COLORS.BATTERYUP,
        backgroundColor: "rgb(66, 216, 40)",
        borderWidth: 0,
      },
      {
        key: 2,
        label: "Prédiction charge batterie",
        image: BatteryUp,
        darkImage: BatteryUp,
        data: pProdConsByBatForecast,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(66, 216, 40,0.5)",
        borderWidth: 0,
        isForecast: true,
      },
      {
        key: 3,
        label: "Surplus réinjecté dans le réseau",
        image: Pylon,
        darkImage: BatteryDown,
        data: pProdSentToGrid,
        bgColor: COLORS.SURPLUS,
        backgroundColor: "rgb(224, 47, 239)",
        borderWidth: 0,
      },
      {
        key: 3,
        label: "Prédiction surplus réinjecté dans le réseau",
        image: Pylon,
        darkImage: Pylon,
        data: pProdSentToGridForecast,
        bgColor: COLORS.SURPLUS,
        backgroundColor: "rgb(224, 47, 239,0.5)",
        borderWidth: 0,
        isForecast: true,
      },
    ],
  };

  const graphConso = {
    labels: labelsConsum,
    datasets: [
      {
        key: 4,
        label: "Autoconsommation directe",
        data: pConsoFromProd,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0)",
        borderWidth: 0,
      },
      {
        key: 4,
        label: "Prédiction autoconsommation directe",
        data: pConsoFromProdForecast,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0, 0.5)",
        borderWidth: 0,
        isForecast: true,
      },
      {
        key: 5,
        label: "Décharge batterie",
        image: BatteryDown,
        darkImage: BatteryDown,
        data: pConsoFromBat,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119)",
        borderWidth: 0,
      },
      {
        key: 5,
        label: "Prédiction décharge batterie",
        image: BatteryDown,
        darkImage: BatteryDown,
        data: pConsoFromBatForecast,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119, 0.5)",
        borderWidth: 0,
        isForecast: true,
      },
      {
        key: 6,
        label: "Consommation réseau",
        image: Pylon,
        darkImage: Pylon,
        data: pConsoFromGrid,
        bgColor: COLORS.CONSO,
        backgroundColor: "rgb(224, 0, 18)",
        borderWidth: 0,
      },
      {
        key: 6,
        label: "Prédiction consommation réseau",
        image: Pylon,
        darkImage: Pylon,
        data: pConsoFromGridForecast,
        bgColor: COLORS.CONSO,
        backgroundColor: "rgb(224, 0, 18, 0.5)",
        borderWidth: 0,
        isForecast: true,
      },
    ],
  };

  return (
    <>
      <Show below="md">
        <ResponsiveLayout>
          <Flex
            flex="2"
            py={2}
            px={2}
            flexDirection={"column"}
            className="overflow-y-auto"
            gap={2}
          >
            <Box
              flex="1"
              className="py-4 w-full md:max-w-fit mx-auto"
              overflow={"auto"}
            >
              <Panel
                title="Mon autonomie"
                withArrow={false}
                className="w-full md:max-w-fit"
              >
                <Switcher>
                  {autonomieIsError ? (
                    <EmsAlert
                      status="error"
                      title={
                        ("status" in autonomieError
                          ? getLoadingDataErrorByStatus(autonomieError.status)
                              .titre
                          : getLoadingDataErrorByStatus(undefined).titre) +
                        " Lors du chargement des données de l'autonomie "
                      }
                      description={
                        "status" in autonomieError
                          ? getLoadingDataErrorByStatus(autonomieError.status)
                              .message
                          : getLoadingDataErrorByStatus(undefined).message
                      }
                    />
                  ) : (
                    <Skeleton isLoaded={autonomieData}>
                      <AutonomieIndicator
                        date={switcherDate}
                        data={autonomieData}
                        period={period}
                        unit={unit}
                      />
                    </Skeleton>
                  )}{" "}
                </Switcher>
              </Panel>
            </Box>
            {monitoringWithForecastIsError ||
            (manualMonitoringResponse[1] &&
              manualMonitoringResponse[1].isError) ? (
              <EmsAlert
                status="error"
                title={"Erreur lors de la récupération des données"}
                description={"Error"}
              ></EmsAlert>
            ) : (
              <>
                <Panel
                  className="max-h-full min-h-full"
                  withArrow={false}
                  title={
                    <div>
                      <div className="indicators__list-indicators__graphs-indProd-title">
                        {" "}
                        Ma production globale d&apos;énergie{" "}
                        <span className=" text-[#0488F9]  text-xl md:text-2xl font-extrabold mx-2">
                          {" "}
                          {pProdGlobal.toFixed(1)} kWh
                        </span>
                      </div>
                    </div>
                  }
                >
                  <Switcher>
                    <Skeleton
                      isLoaded={
                        !(
                          monitoringWithForecastIsLoading ||
                          (manualMonitoringResponse[1] &&
                            manualMonitoringResponse[1].isLoading)
                        )
                      }
                      className="h-full max-h-full min-h-full"
                    >
                      <LabeledBarChart data={graphProd} displayCols />
                    </Skeleton>
                  </Switcher>
                </Panel>

                <Panel
                  className="max-h-full min-h-full"
                  withArrow={false}
                  title={
                    <div className="indicators__list-indicators__graphs-indConso-title">
                      {" "}
                      Ma consommation globale d&apos;énergie{" "}
                      <span className=" text-[#0488F9]  text-xl md:text-2xl font-extrabold mx-2">
                        {" "}
                        {pConsoGlobal.toFixed(1)} kWh
                      </span>
                    </div>
                  }
                >
                  <Switcher>
                    <Skeleton
                      isLoaded={
                        !(
                          monitoringWithForecastIsLoading ||
                          (manualMonitoringResponse[1] &&
                            manualMonitoringResponse[1].isLoading)
                        )
                      }
                      className="h-full max-h-full min-h-full"
                    >
                      <LabeledBarChart data={graphConso} displayCols />
                    </Skeleton>
                  </Switcher>
                </Panel>
              </>
            )}
          </Flex>
        </ResponsiveLayout>
      </Show>
      <Show above="md">
        <ResponsiveLayout
          rightSide={
            <Flex
              flex="2"
              py={2}
              px={2}
              flexDirection={"column"}
              className="overflow-y-auto list"
              gap={2}
            >
              {monitoringWithForecastIsError ||
              (manualMonitoringResponse[1] &&
                manualMonitoringResponse[1].isError) ? (
                <EmsAlert
                  status="error"
                  title={"Erreur lors de la récupération des données"}
                  description={"Error"}
                ></EmsAlert>
              ) : (
                <>
                  <Panel
                    withArrow={false}
                    className="max-h-full min-h-full"
                    title={
                      <div>
                        <div className="indicators__list-indicators__graphs-indProd-title">
                          {" "}
                          Ma production globale d&apos;énergie{" "}
                          <span className=" text-[#0488F9]  text-xl md:text-2xl font-extrabold mx-2">
                            {" "}
                            {pProdGlobal.toFixed(1)} kWh
                          </span>
                        </div>
                      </div>
                    }
                    link="/indicators"
                  >
                    <Switcher>
                      <Skeleton
                        isLoaded={
                          !(
                            monitoringWithForecastIsLoading ||
                            (manualMonitoringResponse[1] &&
                              manualMonitoringResponse[1].isLoading)
                          )
                        }
                        className="h-full max-h-full min-h-full"
                      >
                        <LabeledBarChart data={graphProd} displayCols />
                      </Skeleton>
                    </Switcher>
                  </Panel>

                  <Panel
                    withArrow={false}
                    className="max-h-full min-h-full"
                    title={
                      <div className="indicators__list-indicators__graphs-indConso-title">
                        {" "}
                        Ma consommation globale d&apos;énergie{" "}
                        <span className=" text-[#0488F9]  text-xl md:text-2xl font-extrabold mx-2">
                          {" "}
                          {pConsoGlobal.toFixed(1)} kWh
                        </span>
                      </div>
                    }
                    link="/indicators"
                  >
                    <Switcher>
                      <Skeleton
                        isLoaded={
                          !(
                            monitoringWithForecastIsLoading ||
                            (manualMonitoringResponse[1] &&
                              manualMonitoringResponse[1].isLoading)
                          )
                        }
                        className="h-full max-h-full min-h-full"
                      >
                        <LabeledBarChart data={graphConso} displayCols />
                      </Skeleton>
                    </Switcher>
                  </Panel>
                </>
              )}
            </Flex>
          }
          leftSide={
            <Box
              flex="1"
              className="py-4 w-full md:max-w-fit mx-auto list"
              overflow={"auto"}
            >
              <Panel
                title="Mon autonomie"
                withArrow={false}
                className="w-full md:max-w-fit"
              >
                <Switcher>
                  {autonomieIsError ? (
                    <EmsAlert
                      status="error"
                      title={
                        ("status" in autonomieError
                          ? getLoadingDataErrorByStatus(autonomieError.status)
                              .titre
                          : getLoadingDataErrorByStatus(undefined).titre) +
                        " Lors du chargement des données de l'autonomie "
                      }
                      description={
                        "status" in autonomieError
                          ? getLoadingDataErrorByStatus(autonomieError.status)
                              .message
                          : getLoadingDataErrorByStatus(undefined).message
                      }
                    />
                  ) : (
                    <Skeleton isLoaded={autonomieData}>
                      <AutonomieIndicator
                        date={switcherDate}
                        data={autonomieData}
                        period={period}
                        unit={unit}
                      />
                    </Skeleton>
                  )}{" "}
                </Switcher>
              </Panel>
            </Box>
          }
        ></ResponsiveLayout>
      </Show>
    </>
  );
};
