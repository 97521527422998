import React, { useEffect, useState } from "react";
import { Crud } from "./Crud";
import { ClassNames, PATH_PREFIX_FLUXTOPOLOGY, URIs } from "../utils/toolbox";
import { ErrorBoundary } from "react-error-boundary";
export const CrudUri: React.FC<{
  uri: string;
}> = ({ uri }) => {
  const [selectedUi, setSelectedUri] = useState(uri);
  useEffect(() => {
    setSelectedUri(uri);
  }, [uri]);
  const data = [
    {
      uri: PATH_PREFIX_FLUXTOPOLOGY+"/" +URIs.FluxTopologyURI,
      title: "Flux Topology",
      obj: ClassNames.FLUXTOPOLOGY.toString(),
    },
    {
      uri:PATH_PREFIX_FLUXTOPOLOGY+"/" +URIs.FluxNodeURI,
      title: "Flux Node",
      obj: ClassNames.FLUXNODE.toString(),
    },
    {
      uri: PATH_PREFIX_FLUXTOPOLOGY+"/" +URIs.FluxNodeParameterURI,
      title: "Flux Node Parameter",
      obj: ClassNames.FLUXNODEPARAMETER.toString(),
    },
    {
      uri: PATH_PREFIX_FLUXTOPOLOGY+"/" +URIs.MeasurementSourceURI,
      title: "measurementSource",
      obj: ClassNames.MEASUREMENTSOURCE.toString(),
    },
    {
      uri: PATH_PREFIX_FLUXTOPOLOGY+"/" +URIs.ParameterDescriptorURI,
      title: "Parameter Descriptor",
      obj: ClassNames.PARAMETER_DESCRIPTOR.toString(),
    },
    {
      uri: PATH_PREFIX_FLUXTOPOLOGY+"/" +URIs.MeasurementSourceParameterURI,
      title: "MeasurementSource Parameter",
      obj: ClassNames.MEASUREMENTSOURCEPARAMETER.toString(),
    } ,
    //Energy provider
    {
      uri: URIs.FluxTopologiesURI,
      title: "Topologies",
      obj: ClassNames.FLUXTOPOLOGIES.toString(),
    } ,
    {
      uri: URIs.EnergyProvidersUri,
      title: "Energy Providers",
      obj: ClassNames.ENERGYPROVIDER.toString(),
    } ,
    {
      uri: URIs.ContractsURI,
      title: "Contracts",
      obj: ClassNames.CONTRACTS.toString(),
    } 

  ];
  let uriIndex = 0;
  switch (selectedUi) {
    case URIs.FluxTopologyURI: {
      uriIndex = 0;
      break;
    }
    case URIs.FluxNodeURI: {
      uriIndex = 1;
      break;
    }
    case URIs.FluxNodeParameterURI: {
      uriIndex = 2;
      break;
    }
    case URIs.MeasurementSourceURI: {
      uriIndex = 3;
      break;
    }
    case URIs.ParameterDescriptorURI: {
      uriIndex = 4;
      break;
    }
    case URIs.MeasurementSourceParameterURI: {
      uriIndex = 5;
      break;
    }
    case URIs.FluxTopologiesURI : {
      uriIndex = 6
      break
    }
    case URIs.EnergyProvidersUri : {
      uriIndex=7
      break
    }
    case URIs.ContractsURI : {
      uriIndex = 8
      break
    }
   
  }
  return (
    <ErrorBoundary
      fallback={<div className="red-500">Une erreur est survenue</div>}
    >
      <Crud {...data[uriIndex]} />
    </ErrorBoundary>
  );
};
