import { Showforpermission } from "../components/Showforpermission";
import { Navigate, Outlet } from "react-router-dom";
import { selectAuth } from "../reducers/authReducer";
import { store } from "../store/config";
import AppBar from "../components/AppBar";
import "../assets/scss/routes/PrivateRoute.scss";
import React from "react";
import { isAuthorized } from "../utils/toolbox";
interface PrivateRouteProps {
  allowedRoles: string[];
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({ allowedRoles }) => {
  const auth = selectAuth(store.getState());
  if (!auth.isAuthenticated) return <Navigate to="/login" />;
  return isAuthorized(allowedRoles, auth?.user?.token || "") ? (
    <>
      <Outlet />{" "}
    </>
  ) : (
    <Navigate to="/unauthorized" />
  );
};
