/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import EmsAlert from "../components/EmsAlert";
import { OptionType, RadioForm } from "../components/RadioForm";
import {
  AdvancedFluxNodeDto,
  AdvancedFluxTopologyBody,
  ResponseType,
  topologyApi,
} from "../services/topology.service";
import { TopologyConfigParams } from "./TopologyConfigParams";
import { useGetUsersByRolesQuery } from "../services/users.service";
import {
  getLoadingDataErrorByStatus,
  getUserRoles,
  ROLES,
  userRoles,
} from "../utils/toolbox";
import { selectAuth } from "../reducers/authReducer";
import { store } from "../store/config";

export interface ParameterDescriptor extends ResponseType {
  id: string;
  flux_node_type: string;
  measurement_source_type: string;
  name: string;
  type: string;
  unit: string;
  visibility: boolean;
  requirement: boolean;
  connector: string;
  defaultValue: string;
  viewName: string;
  typeValues: string;
}

export interface ParameterDescriptorDto {
  id: string;
  name: string;
}

export const FluxTopologyPage: React.FC = () => {
  const auth = selectAuth(store.getState());
  const toast = useToast();
  const { useAvdancedCreateMutation } = topologyApi;
  const [createTopo, createTopoResponse] = useAvdancedCreateMutation();
  const {
    data: users,
    error: errorUsers,
    isError: isErrorUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersByRolesQuery({
    roles: getUserRoles(auth?.user?.token || "")
      ?.filter((r) => r.startsWith("WEBAPP_USER_"))
      .includes(ROLES.WEBAPP_USER_IFPEN)
      ? userRoles()
      : getUserRoles(auth?.user?.token || "")?.filter((r) =>
          r.startsWith("WEBAPP_USER_")
        ) || [],
  });
  const [typeTopology, setTypeTopology] = useState<string>("RESIDENTIAL");
  const [selectedModele, setSelectedModele] = useState<string>("pvPlusBatt");
  useEffect(() => {
    setOptions(() => [
      {
        value: "GROUP",
        viewValue: (
          <div>
            <h1 className="">Compteur réseau</h1>
          </div>
        ),
        status: "started",
      },
      {
        value: "CONSUMER",
        viewValue: (
          <div>
            <h1 className="">Consommation</h1>
          </div>
        ),
        status: "started",
      },
      {
        value: "PRODUCER",
        viewValue: (
          <div>
            <h1 className="">Producteur</h1>
          </div>
        ),
        status: "started",
      },
      {
        value: "BATTERY",
        viewValue: (
          <div>
            <h1 className="">Batterie</h1>
          </div>
        ),
        status: "started",
      },
    ]);
  }, []);

  const [options, setOptions] = useState<OptionType[]>([]);
  const [configParamsFinished, setconfigParamsFinished] = useState(false);
  const [finalResult, setFinalResult] = useState<AdvancedFluxTopologyBody>({
    advancedFluxNodeDtos: {
      GROUP: {
        typeNoeud: "GROUP",
        fluxNodeParameters: [],
        measurementSourceParameters: [],
      },
      CONSUMER: {
        typeNoeud: "CONSUMER",
        fluxNodeParameters: [],
        measurementSourceParameters: [],
      },
      PRODUCER: {
        typeNoeud: "PRODUCER",
        fluxNodeParameters: [],
        measurementSourceParameters: [],
      },
      BATTERY: {
        typeNoeud: "BATTERY",
        fluxNodeParameters: [],
        measurementSourceParameters: [],
      },
    },
    latitude: 0,
    longitude: 0,
    adresse: "",
    owner: {
      id: "",
      groupe: "SOALIS",
    },
  });
  const schema = Yup.object().shape({
    user: Yup.object().shape({
      id: Yup.mixed().required("Veuillez séléctionner un utilisateur !"),
    }),
    name: Yup.string().required("Ce champ est obligatoire"),
    //image: Yup.string().required("Ce champ est obligatoire"),
    adresse: Yup.string().required("Ce champ est obligatoire"),
    latitude: Yup.string().required("Ce champ est obligatoire"),
    longitude: Yup.string().required("Ce champ est obligatoire"),
    installationDate: Yup.string().required("Ce champ est obligatoire"),
  });
  const convertToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = (setValues: any) => async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setValues((prev: any) => {
      return {
        ...prev,
        image: base64,
      };
    });
  };

  return (
      <Formik
        validationSchema={schema}
        initialValues={{
          name: "",
          adresse: "",
          image: "",
          latitude: 14,
          longitude: 14,
          typeTopology: "RESIDENTIAL",
          user: { id: null },
          installationDate: new Date().toLocaleDateString("en-CA"),
          deviceId: "",
        }}
        onSubmit={(values) => {
          const advancedFluxNodeDtos: AdvancedFluxNodeDto[] = [];
          Object.entries(finalResult.advancedFluxNodeDtos).forEach((entry) => {
            if (entry[1] != undefined) {
              advancedFluxNodeDtos.push(entry[1]);
            }
          });
          createTopo({
            uri: "fluxTopology",
            body: {
              ...finalResult,
              advancedFluxNodeDtos,
              name: values["name"],
              typeTopology: values["typeTopology"],
              image: values["image"],
              adresse: values["adresse"],
              latitude: values["latitude"],
              longitude: values["longitude"],
              deviceId: values["deviceId"],
              owner: {
                ...values["user"],
              },
              installationDate: new Date(values["installationDate"]),
            },
          })
            .unwrap()
            .then(() => {
              toast({
                description: "La topologie a été créée avec succès",
                status: "success",
                duration: 9000,
                position: "top",
                isClosable: true,
              });
            })
            .catch((err) => {
              if (err && err.data && err.data.status == 409) {
                toast({
                  title: "Erreur",
                  description:
                    "Une topologie avec le nom " +
                    values["name"] +
                    " existe déja.",
                  status: "error",
                  position: "top",
                  duration: 9000,
                  isClosable: true,
                });
              } else {
                toast({
                  title: "Erreur",
                  description:
                    "Une Erreur s'est produite lors de la création de la topologie.",
                  status: "error",
                  position: "top",
                  duration: 9000,
                  isClosable: true,
                });
              }
            });
        }}
      >
        {({ handleSubmit, errors, setValues, getFieldProps }) => (
          //Change with Form
          <Form className="pt-4 pl-4 flex flex-col gap-2 md:w-1/2 p-2 pb-12" style={{
            backgroundColor:"white",
            margin:"4px"
          }}>
            <FormControl className="flex flex-row items-center">
              <FormLabel className="min-w-fit">
                <h1 className="text-left font-bold  text-xl my-auto">
                  <span className=" text-xl md:text-2xl text-[#003265] font-extrabold">
                    Propriétaire
                  </span>{" "}
                  <span className="text-red-500 my-auto pl-2">*</span>
                </h1>
              </FormLabel>
              {isErrorUsers ? (
                <div>
                  <EmsAlert
                    status={"error"}
                    title={
                      "status" in errorUsers
                        ? getLoadingDataErrorByStatus(errorUsers.status).titre
                        : getLoadingDataErrorByStatus(undefined).titre
                    }
                    description={
                      "status" in errorUsers
                        ? getLoadingDataErrorByStatus(errorUsers.status).message
                        : getLoadingDataErrorByStatus(undefined).message
                    }
                  />
                </div>
              ) : isLoadingUsers ? (
                <div>Loading ... </div>
              ) : (
                <div className="flex flex-col w-full">
                  <Field as={Select} {...getFieldProps("user.id")}>
                    <option value={""}>Selectionner un utilisateur</option>
                    {users &&
                      users?.map((user) => {
                        return (
                          <option key={user.id} value={user.id}>
                            {user.lastName + " " + user.firstName}
                          </option>
                        );
                      })}
                  </Field>
                  <ErrorMessage
                    name="user.id"
                    component={"div"}
                    className="text-red-500"
                  />
                </div>
              )}
            </FormControl>

            <h1 className="text-left    text-xl md:text-2xl   text-[#003265] font-bold font-extrabold text-xl my-auto">
              Informations générales
            </h1>
            <FormControl className="flex flex-row items-center">
              <FormLabel className="min-w-fit">
                <h1 className="min-w-fit font-extrabold flex flex-row items-center content-center">
                  Adresse <span className="text-red-500 my-auto pl-2">*</span>
                </h1>
              </FormLabel>
              <div className="flex flex-col w-full">
                <Field as={Input} type={"text"} {...getFieldProps("adresse")} />
                <ErrorMessage
                  name="adresse"
                  component={"div"}
                  className="text-red-500"
                />
              </div>
            </FormControl>
            <FormControl className="flex flex-row items-center">
              <FormLabel className="min-w-fit">
                <h1 className="min-w-fit font-extrabold flex flex-row items-center content-center">
                  Nom de la topologie{" "}
                  <span className="text-red-500 my-auto pl-2">*</span>
                </h1>
              </FormLabel>
              <div className="flex flex-col w-full">
                <Field as={Input} type={"text"} {...getFieldProps("name")} />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </FormControl>
            <FormControl className="flex flex-row items-center">
              <FormLabel className="min-w-fit">
                <h1 className="min-w-fit font-extrabold flex flex-row items-center content-center">
                  Image
                </h1>
              </FormLabel>
              <div className="flex flex-col w-full">
                <Field
                  as={Input}
                  type={"file"}
                  name={"file"}
                  accept="image/*"
                  onChange={handleFileUpload(setValues)}
                />
                {errors && errors["image"] && (
                  <div className="text-red-500">{errors["image"]}</div>
                )}
              </div>
            </FormControl>
            <FormControl className="flex flex-row items-center">
              <FormLabel className="min-w-fit">
                <h1 className="min-w-fit font-extrabold flex flex-row items-center content-center">
                  Latitude (°){" "}
                  <span className="text-red-500 my-auto pl-2">*</span>
                </h1>
              </FormLabel>
              <div className="flex flex-col w-full">
                <Field
                  as={Input}
                  type={"number"}
                  {...getFieldProps("latitude")}
                />
                <ErrorMessage
                  name="latitude"
                  component={"div"}
                  className="text-red-500"
                />
              </div>
            </FormControl>
            <FormControl className="flex flex-row items-center">
              <FormLabel className="min-w-fit">
                <h1 className="min-w-fit font-extrabold flex flex-row items-center content-center">
                  Longitude (°){" "}
                  <span className="text-red-500 my-auto pl-2">*</span>
                </h1>
              </FormLabel>
              <div className="flex flex-col w-full">
                <Field
                  as={Input}
                  type={"number"}
                  {...getFieldProps("longitude")}
                />
                <ErrorMessage
                  name="longitude"
                  component={"div"}
                  className="text-red-500"
                />
              </div>
            </FormControl>
            <FormControl className="flex flex-row items-center">
              <FormLabel className="min-w-fit">
                <h1 className="min-w-fit font-extrabold flex flex-row items-center content-center">
                  Date d&apos;installation{" "}
                  <span className="text-red-500 my-auto pl-2">*</span>
                </h1>
              </FormLabel>
              <div className="flex flex-col w-full">
                <Field
                  as={Input}
                  type={"date"}
                  {...getFieldProps("installationDate")}
                />
                <ErrorMessage
                  name="installationDate"
                  component={"div"}
                  className="text-red-500"
                />
              </div>
            </FormControl>
            <h1 className="text-left    text-xl md:text-2xl   text-[#003265] font-bold font-extrabold text-xl my-auto">
              Type de la topologie
            </h1>
            <FormControl className="control">
              <RadioForm
                value={typeTopology}
                options={[
                  {
                    value: "RESIDENTIAL",
                    viewValue: (
                      <div>
                        <h1 className="">RESIDENTIAL</h1>
                      </div>
                    ),
                  },
                  {
                    value: "TERTIARY",
                    viewValue: (
                      <div>
                        <h1 className="">TERTIARY</h1>
                      </div>
                    ),
                  },
                  {
                    value: "INDUSTRIAL",
                    viewValue: (
                      <div>
                        <h1 className="">INDUSTRIAL</h1>
                      </div>
                    ),
                  },
                ]}
                onChange={(v) => {
                  setTypeTopology(v);
                  setValues((prev) => {
                    return {
                      ...prev,
                      typeTopology: v,
                    };
                  });
                }}
                defaultValue={"RESIDENTIAL"}
              ></RadioForm>
            </FormControl>
            <h1 className="text-left    text-xl md:text-2xl   text-[#003265] font-bold font-extrabold text-xl my-auto">
              Quelle configuration souhaitez-vous ?
            </h1>

            <FormControl className="control">
              <RadioForm
                value={selectedModele}
                options={[
                  {
                    value: "pvPlusBatt",
                    viewValue: (
                      <div>
                        <h1 className="">
                          Consommation + Producteur PV + Batterie
                        </h1>
                      </div>
                    ),
                  },
                  {
                    value: "pv",
                    viewValue: (
                      <div>
                        <h1 className="">Consommation + Producteur PV</h1>
                      </div>
                    ),
                  },
                  {
                    value: "batt",
                    viewValue: (
                      <div>
                        <h1 className="">Consommation + Batterie</h1>
                      </div>
                    ),
                  },
                ]}
                onChange={(v) => {
                  setSelectedModele(() => v);
                  setOptions(() =>
                    v == "pvPlusBatt"
                      ? [
                          {
                            value: "GROUP",
                            viewValue: (
                              <div>
                                <h1 className="">Compteur réseau</h1>
                              </div>
                            ),
                            status: "started",
                          },
                          {
                            value: "CONSUMER",
                            viewValue: (
                              <div>
                                <h1 className="">Consommation</h1>
                              </div>
                            ),
                            status: "started",
                          },
                          {
                            value: "PRODUCER",
                            viewValue: (
                              <div>
                                <h1 className="">Producteur</h1>
                              </div>
                            ),
                            status: "started",
                          },
                          {
                            value: "BATTERY",
                            viewValue: (
                              <div>
                                <h1 className="">Batterie</h1>
                              </div>
                            ),
                            status: "started",
                          },
                        ]
                      : v == "pv"
                      ? [
                          {
                            value: "GROUP",
                            viewValue: (
                              <div>
                                <h1 className="">Compteur réseau</h1>
                              </div>
                            ),
                            status: "started",
                          },
                          {
                            value: "CONSUMER",
                            viewValue: (
                              <div>
                                <h1 className="">Consommation</h1>
                              </div>
                            ),
                            status: "started",
                          },
                          {
                            value: "PRODUCER",
                            viewValue: (
                              <div>
                                <h1 className="">Producteur</h1>
                              </div>
                            ),
                            status: "started",
                          },
                        ]
                      : [
                          {
                            value: "GROUP",
                            viewValue: (
                              <div>
                                <h1 className="">Compteur réseau</h1>
                              </div>
                            ),
                            status: "started",
                          },
                          {
                            value: "CONSUMER",
                            viewValue: (
                              <div>
                                <h1 className="">Consommation</h1>
                              </div>
                            ),
                            status: "started",
                          },
                          {
                            value: "BATTERY",
                            viewValue: (
                              <div>
                                <h1 className="">Batterie</h1>
                              </div>
                            ),
                            status: "started",
                          },
                        ]
                  );
                }}
                defaultValue={"pvPlusBatt"}
              ></RadioForm>
            </FormControl>
            <h1 className="text-left    text-xl md:text-2xl   text-[#003265] font-bold font-extrabold text-xl my-auto">
              Appairage
            </h1>
            <FormControl className="flex flex-row items-center">
              <FormLabel className="min-w-fit">
                <h1 className="min-w-fit font-extrabold flex flex-row items-center content-center">
                  Inventaire id{" "}
                </h1>
              </FormLabel>
              <div className="flex flex-col w-full">
                <Field
                  as={Input}
                  type={"text"}
                  {...getFieldProps("deviceId")}
                />
                <ErrorMessage
                  name="deviceId"
                  component={"div"}
                  className="text-red-500"
                />
              </div>
            </FormControl>
            <div>
              {/* {showMessageElementConfigurationCompleted && (
              <EmsAlert
                status={"success"}
                title={""}
                description={
                  <div className="flex flex-row gap-2">
                    L'installation du{" "}
                    {showMessageElementConfigurationCompleted.viewValue} a été
                    configurée avec succès.
                  </div>
                }
              />
            )} */}
            </div>
            <TopologyConfigParams
              options={options}
              setOptions={setOptions}
              selectedModele={selectedModele}
              typeTopology={typeTopology}
              valider={(
                selectedNode: string,
                params: {
                  GROUP: AdvancedFluxNodeDto;
                  CONSUMER: AdvancedFluxNodeDto;
                  PRODUCER?: AdvancedFluxNodeDto;
                  BATTERY?: AdvancedFluxNodeDto;
                },
                formNotValid: boolean,
                configFinished: boolean
              ) => {
                setFinalResult((prev) => {
                  return {
                    ...prev,
                    advancedFluxNodeDtos: params,
                  };
                });
                setconfigParamsFinished(configFinished);
              }}
            />
            {/* add energy provider */}
            <FormControl className="control">
              <Button
                disabled={
                  Object.keys(errors).length > 0 || !configParamsFinished
                }
                colorScheme={"#003265"}
                className={"rounded-md md:w-1/3 mx-auto "}
                size={"lg"}
                style={{
                  backgroundColor: "#003265",
                  color: "white",
                  borderWidth: "2px",
                  borderColor: "teal.600",
                }}
                _hover={{
                  backgroundColor: "#003265",
                  color: "white",
                  borderColor: "teal.600",
                }}
                type={"button"}
                onClick={() => {
                  // if (checkData()) {
                  if (Object.keys(errors).length > 0 || !configParamsFinished) {
                    toast({
                      title: "Erreur",
                      description: "Veuillez vérifier tous les champs !",
                      status: "error",
                      duration: 9000,
                      position: "top",
                      isClosable: true,
                    });
                  } else {
                    handleSubmit();
                  }

                  //  }
                }}
                isLoading={createTopoResponse.isLoading}
              >
                Créer la topologie
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
  );
};
