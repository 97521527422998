import { ArcElement, Chart, Tooltip } from "chart.js";
import DataLabel from "chartjs-plugin-datalabels";
import React from "react";
import "../assets/scss/pages/HomePage.scss";
import { HomePageGraphs } from "../components/HomePageGraphs";
import { HomeScreen } from "../components/HomeScreen";
import { ResponsiveLayout } from "../layouts/ResponsiveLayout";
import { Box, Flex, Show } from "@chakra-ui/react";
Chart.register(ArcElement, Tooltip, DataLabel);

export const HomePage: React.FC = () => {
  return (
    <>
      <Show above="md">
        <ResponsiveLayout>
          <Flex
            flexDirection={"row"}
            flex="2"
            py={2}
            px={2}
            className="overflow-y-auto  "
            gap={2}
            maxH="calc(100vh - 10rem)" // Pour éviter de déborder
            w={"100%"}
          >
            <Box flex="1" className="mx-auto list">
              <HomeScreen />
            </Box>
            <Flex
              flexDirection={"column"}
              flex="1"
              py={2}
              px={2}
              className="overflow-y-auto list"
              gap={2}
            >
              <HomePageGraphs />
            </Flex>
          </Flex>
        </ResponsiveLayout>
      </Show>
      <Show below="md">
        <ResponsiveLayout>
          <Flex
            flexDirection={"column"}
            flex="2"
            py={2}
            px={3}
            className="overflow-y-auto"
            gap={2}
          >
            <HomeScreen />
            <HomePageGraphs />
          </Flex>
        </ResponsiveLayout>
      </Show>
    </>
  );
};
