import { Card, Icon } from "@chakra-ui/react";
import React, { ReactNode } from "react"
interface IndicatorProps {
    icon :  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
    color:string;
    value:ReactNode;
    description:ReactNode;
}
export const Indicator : React.FC<IndicatorProps> = ({icon,color, value, description})=>{
    return <>
    <Card className="p-4 w-full flex flex-col items-center gap-4">
        <div className="rounded-full p-2" style={{
             backgroundColor : color
        }}>
        <Icon
        boxSize={10}
        backgroundColor={color}
        className="rounded-full"
            as={icon} />
        </div>
        <div style={{
             color : color
        }}   className={`  flex flex-wrap text-center justify-center items-center font-semibold`}>
            {description}
        </div>
        <div style={{
             backgroundColor : color
        }} className={`flex flex-row justify-center items-center text-center  rounded-[20px] text-white font-bold max-w-fit py-2 px-2 md:px-4 mx-auto`}>
            {value}
        </div>
       
    </Card>
    </> 
}