/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/react";
import "../assets/scss/pages/LoginPage.scss";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form, FieldProps } from "formik";
import { AuthRequestType, useLoginMutation } from "../services/auth.service";
import { AuthState } from "../services/users.service";
import { useDispatch } from "react-redux";
import { loginAction, selectAuth } from "../reducers/authReducer";
import { store } from "../store/config";
import EmsAlert from "../components/EmsAlert";
import { color } from "framer-motion";

const LoginPage: React.FC = () => {
  //Yup validation
  const schema = Yup.object().shape({
    username: Yup.string().required("Nom d'utilisateur est obligatoire"),
    password: Yup.string().required("Mot de passe est obligatoire"),
  });
  const auth = selectAuth(store.getState());
  const navigate = useNavigate();
  useEffect(() => {
    if (auth?.isAuthenticated) {
      navigate("/redirect");
    }
  }, [auth?.user]);
  const [loginFn, { isError, isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const login = (auth: AuthRequestType) => {
    loginFn(auth)
      .unwrap()
      .then((res) => {
        const authenticatedUser: AuthState = {
          token: res.access_token,
          refresh_token: res.refresh_token,
        };
        dispatch(
          loginAction({
            isAuthenticated: true,
            user: { ...authenticatedUser },
          })
        );
        navigate("/redirect");
        return;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loginDemo = () => {
    login({
      username: "emslabuserdemo@test.com",
      password: "486e*$qMSP&NS",
    });
  };
  return (
    <div className="flex flex-col justify-center max-h-fit  items-center md:mx-2 w-full md:w-full md:gap-2 my-auto h-full">
      <h1 className="text-4xl md:text-3xl my-3 md:my-4 font-bold text-white md:text-sky-700  ">
        Bienvenue !
      </h1>
      <p className="md:text-xl md:text-sky-900 text-white md:block">
        <br /> Connectez-vous avec vos identifiants :
      </p>
      <Formik
        validationSchema={schema}
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={(values) => {
          const body = {
            ...values,
          };
          login(body);
        }}
      >
        {({ values, getFieldProps }) => (
          <Form
            className="flex flex-col items-center px-3 md:p-0 w-full gap-y-12 my-10 mx-2 w-full"
            method="POST"
          >
            {isError && (
              <EmsAlert
                status={"error"}
                title="Veuillez vérifier vos informations"
                description=""
              ></EmsAlert>
            )}
            <FormControl className="md:max-w-sm">
              <div className="flex flex-col w-full">
                <Field
                  as={Input}
                  variant={"flushed"}
                  type={"text"}
                  placeholder="Nom d'utilisateur"
                  size={"lg"}
                  fontSize="1.2em"
                  pl="5px"
                  _placeholder={{
                    opacity: 1,
                    color: {
                      base: "white",
                      md: "black",
                    },
                    fontWeight: "bold",
                  }}
                  className="text-white md:text-black font-bold rounded-xl text-shadow-lg "
                  {...getFieldProps("username")}
                />
                <ErrorMessage
                  name="username"
                  component={"div"}
                  className="text-red-500"
                />
              </div>
            </FormControl>
            <FormControl className="md:max-w-sm">
              <div className="flex flex-col w-full">
                <Field
                  type={"text"}
                  placeholder="Mot de passe"
                  size={"lg"}
                  fontSize="1.2em"
                  pl="5px"
                  _placeholder={{
                    opacity: 1,
                    color: {
                      base: "white",
                      md: "black",
                    },
                    fontWeight: "bold",
                  }}
                  className="text-white md:text-black font-bold rounded-xl text-shadow-lg "
                  {...getFieldProps("password")}
                  component={PasswordInput}
                />
                <ErrorMessage
                  name="password"
                  component={"div"}
                  className="text-red-500"
                />
              </div>
            </FormControl>
            <Button
              backgroundColor={"#1DA1F2"}
              color={"white"}
              _hover={{ color: "black" }}
              className="rounded-md mx-auto max-w-fit"
              size={"lg"}
              onClick={() => {
                login(values);
              }}
              disabled={isLoading}
            >
              CONNEXION
            </Button>
            <Button
              backgroundColor={"#1DA1F2"}
              color={"white"}
              _hover={{ color: "black" }}
              className="rounded-md mx-auto max-w-fit"
              size={"lg"}
              type="button"
              onClick={() => {
                loginDemo();
              }}
            >
              DEMO
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

function PasswordInput({ field, form, meta, ...props }: FieldProps) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md" variant={"flushed"} className="  border-none">
      <Input
        {...field}
        {...props}
        type={show ? "text" : "password"}
        placeholder="Mot de passe"
      />
      <InputRightElement width="4.5rem" className="relative h-full pt-3  ">
        {show ? (
          <>
            <div className="hidden md:block">
              <ViewOffIcon
                color={"blue"}
                boxSize={6}
                className=" mb-0"
                onClick={handleClick}
              />
            </div>
            <div className="block md:hidden">
              <ViewOffIcon
                color={"white"}
                boxSize={6}
                className=" mb-0"
                onClick={handleClick}
              />
            </div>
          </>
        ) : (
          <>
            <div className="hidden md:block">
              <ViewIcon
                color={"blue"}
                boxSize={6}
                className=" mb-0"
                onClick={handleClick}
              />
            </div>
            <div className="block md:hidden">
              <ViewIcon
                color={"white"}
                boxSize={6}
                className=" mb-0"
                onClick={handleClick}
              />
            </div>
          </>
        )}
      </InputRightElement>
    </InputGroup>
  );
}
export default LoginPage;
