import React, {
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useEffect,
} from "react";
import "../assets/scss/components/Switcher.scss";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";
import moment, { DurationInputArg2, unitOfTime } from "moment";
import { Box, Button, Flex, HStack } from "@chakra-ui/react";
import { displayUnit, useSwitcherContext } from "../contexts/SwitcherContext";
import { OptionType, RadioForm } from "./RadioForm";
import { symbolMap } from "../utils/toolbox";
registerLocale("fr", fr);

interface SwitcherProps {
  units?: string[];
  periods?: string[];
  children?: ReactNode;
  withUnits?: boolean;
  withDates?: boolean;
  withToday?: boolean;
  withPeriods?: boolean;
  className?: string;
  customStyleInput?: object;
  customStyleChevronLeftIcon?: object;
  customStyleChevronRightIcon?: object;
}
export const Switcher: React.FC<SwitcherProps> = ({
  units,
  children,
  periods,
  withUnits = true,
  withDates = true,
  withToday = true,
  withPeriods = true,
  className,
  customStyleInput,
  customStyleChevronLeftIcon,
  customStyleChevronRightIcon,
}) => {
  const toOptionType = (options: string[]) => {
    return options.map((o) => {
      const optionType: OptionType = {
        value: o,
        viewValue: getPeriodeName(o),
      };
      return optionType;
    });
  };
  const unitsToOptionType = (options: string[]) => {
    return options.map((o) => {
      const optionType: OptionType = {
        value: o,
        viewValue: getUnitName(o),
      };
      return optionType;
    });
  };
  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();

  const [showIconRight, setShowIconRight] = useState(true);
  const nowCheckedStyle = moment(switcherDate).isSame(new Date(), "day")
    ? { bgColor: "#0087ff", color: "white" }
    : {};
  useEffect(() => {
    setShowIconRight(showRightIcon(period, switcherDate));
  }, [period, switcherDate]);
  const showRightIcon = (period: string, switcherDate: Date) => {
    const tomorrow: Date = moment(new Date()).add(1, "day").toDate();
    return moment(switcherDate).isBefore(
      tomorrow,
      period as unitOfTime.StartOf
    );
  };
  const increment = () => {
    const tomorrow: Date = moment(new Date()).add(1, "day").toDate();
    if (
      moment(switcherDate)
        .add(1, period as DurationInputArg2)
        .isAfter(tomorrow, "date")
    ) {
      setSwitcherDate(tomorrow);
    } else {
      setSwitcherDate(() =>
        moment(switcherDate)
          .add(1, period as DurationInputArg2)
          .toDate()
      );
    }
  };
  const decriment = () => {
    setSwitcherDate(() =>
      moment(switcherDate)
        .subtract(1, period as DurationInputArg2)
        .toDate()
    );
  };
  const getPeriodeName = (p: string) => {
    if (p == "day") return "Jour";
    if (p == "week") return "Semaine";
    if (p == "month") return "Mois";
    if (p == "year") return "Année";
    if (p == "now") return "Aujourd'hui";
  };
  const getUnitName = (u: string) => {
    return displayUnit(u, symbolMap);
  };
  const CustomInputDateComponent = (
    {
      value,
      onClick,
    }: {
      value?: string;
      onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    },
    ref: React.LegacyRef<HTMLButtonElement> | undefined
  ) => (
    <Button
      onClick={onClick}
      ref={ref}
      style={customStyleInput}
      variant={"unstyled"}
      rightIcon={<CalendarIcon />}
      color={"#0087ff"}
      className="text-md font-bold"
    >
      {period == "day" && moment(switcherDate).isSame(new Date(), "day")
        ? "Aujourd'hui"
        : period == "day" &&
            moment(switcherDate).isSame(
              moment(new Date()).add(1, "day").toDate(),
              "day"
            )
          ? "Demain"
          : period == "week"
            ? `Semaine ${value}`
            : value}
    </Button>
  );
  const CustomInputDate = forwardRef(CustomInputDateComponent);
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-between"}
      className={`"w-full h-full flex flex-col gap-2 md:gap-4 py-2" ${className}`}
    >
      {withDates && (
        <Flex
          flexDirection={"column"}
          maxH={"fit-content"}
          justifyContent={"start"}
        >
          {" "}
          <Box className=" flex flex-row justify-center gap-4">
            <Button
              style={customStyleChevronLeftIcon}
              color={"#0087ff"}
              onClick={decriment}
              variant={"unstyled"}
            >
              <ChevronLeftIcon boxSize={6} />
            </Button>
            <div className="w-full max-w-fit text-center py-auto flex justify-center">
              <DatePicker
                showYearPicker={period == "year"}
                dateFormat={
                  period == "year"
                    ? "yyyy"
                    : period == "week"
                      ? "I/R"
                      : period == "month"
                        ? "MMMM yyyy"
                        : "dd MMMM yyyy"
                }
                locale={"fr"}
                showWeekNumbers={period == "week"}
                showWeekPicker={period == "week"}
                showMonthYearPicker={period == "month"}
                selected={switcherDate}
                maxDate={moment(new Date()).add(1, "day").toDate()}
                customInput={<CustomInputDate />}
                onChange={(e: Date|null) => {
                  if(e){
                    setSwitcherDate(e);
                  }  
                }}
              />
            </div>
            {
              <Button
                onClick={increment}
                disabled={!showIconRight}
                opacity={showIconRight ? "initial" : 0.5}
                variant={"unstyled"}
                style={customStyleChevronRightIcon}
                color={"#0087ff"}
              >
                <ChevronRightIcon boxSize={6} />
              </Button>
            }
          </Box>
          <Box className="w-full flex flex-row gap-2">
            {withPeriods && (
              <HStack
                flexDirection={"row"}
                flexWrap={"wrap"}
                justifyContent={"center"}
                width={"full"}
              >
                {withToday && (
                  <Box
                    cursor="pointer"
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="md"
                    borderColor={"#0087ff"}
                    px={"3"}
                    py={"2"}
                    {...nowCheckedStyle}
                    onClick={() => setSwitcherDate(new Date())}
                    className="flex items-center justify-center font-bold text-[#0087ff]"
                  >
                    Aujourd&apos;hui
                  </Box>
                )}
                {
                  <RadioForm
                    className="font-bold text-[#0087ff]"
                    px={"3"}
                    py={"2"}
                    options={
                      periods
                        ? toOptionType(periods)
                        : toOptionType(["day", "week", "month", "year"])
                    }
                    onChange={(e) => setPeriod(e)}
                    value={period}
                    checkedBgColor="#0087ff"
                    borderColor="#0087ff"
                    defaultValue={"day"}
                  />
                }
              </HStack>
            )}
          </Box>{" "}
        </Flex>
      )}
      <Box className="py-2 h-full">{children}</Box>
      {withUnits && (
        <Box className="w-full flex max-h-fit flex-row gap-2 justify-center">
          <HStack
            flexDirection={"row"}
            flexWrap={"wrap"}
            justifyContent={"space-around"}
            rowGap={8}
            width={"80%"}
            className="border-b-4 border-b-[#0087ff]"
            maxWidth={"300px"}
            height={"full"}
          >
            {(units
              ? unitsToOptionType(units)
              : unitsToOptionType(["Euro", "kWh", "Pct"])
            ).map((u: OptionType, index: number) => {
              return (
                <p
                  key={index}
                  className={`px-2 h-full font-bold text-[#0087ff] cursor-pointer ${
                    unit == u.value ? "border-b-4 border-b-[#0087ff]" : ""
                  }`}
                  onClick={() => setUnit(u.value)}
                >
                  {u.viewValue}
                </p>
              );
            })}
          </HStack>
        </Box>
      )}
    </Flex>
  );
};
