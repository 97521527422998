import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { selectAuth } from "../reducers/authReducer";
import { useGetUsersByRolesQuery } from "../services/users.service";
import { store } from "../store/config";
import { getUserRoles, ROLES, userRoles } from "../utils/toolbox";
import React from "react"

export const UsersAdminPage : React.FC = ()=>{
  const auth = selectAuth(store.getState())
    const { data:users,error : errorUsers, isError:isErrorUsers, isLoading :isLoadingUsers} = useGetUsersByRolesQuery({
        roles: getUserRoles(auth?.user?.token || "")?.filter(r=>r.startsWith("WEBAPP_USER_")).includes(ROLES.WEBAPP_USER_IFPEN) ? userRoles() : getUserRoles(auth?.user?.token || "")?.filter(r=>r.startsWith("WEBAPP_USER_")) || []
      });

    return  <div className="">
        <h1 className="font-extrabold text-xl m-2">Liste des utilisateurs <span className="text-blue-500">
        {getUserRoles(auth?.user?.token || "")?.filter(r=>r.startsWith("WEBAPP_USER_")).map(r=>{

// Use a regular expression to extract the part after "WEBAPP_USER_"
const match = r.match(/^WEBAPP_USER_(\w+)$/);

// If the role matches the pattern, the extracted part will be in match[1]
if (match) {
  return match[1];
} else {
  return ""
}
}).filter(r=>r!=="").join(";")}
            </span> </h1>
<TableContainer
    className="max-w-screen border border-b-2 pb-64"
    overflowY={"scroll"}
  >
      <Table variant="simple" className="max-w-screen">
        <Thead
          position={"sticky"}
          zIndex={100}
          top={0}
          opacity={1}
          backgroundColor={"#003265"}
          textColor={"white"}
        >
          <Tr textColor={"white"}>
              <Th textColor={"white"} className="">
               ID
              </Th>
              <Th textColor={"white"} className="">
              lastName
              </Th>
              <Th textColor={"white"} className="">
              firstName
              </Th>
              <Th textColor={"white"} className="">
              email
              </Th>
              <Th textColor={"white"} className="">
              roles webapp
              </Th>
              <Th textColor={"white"} className="">
              roles
              </Th>
                <Th textColor={"white"} textAlign={"center"}>
                enabled{" "}
                </Th>
          </Tr>
        </Thead>
        <Tbody overflow={"scroll"}>
          {users &&
            users.map((user, index) => {
              // let keys = columns;
              //let values = Object.values(row);
              // let id = values[keys.indexOf("id")];
              return (
                <Tr key={index} className="font-bold">
                  <Td>{user.id}</Td>
                  <Td>{user.lastName}</Td>
                  <Td>{user.firstName}</Td>
                  <Td>{user.email}</Td>
                  <Td> <ul className="max-h-[200px] overflow-y-auto">{user.realmRoles.filter(role=>role.startsWith("WEBAPP_")).map(role=><li>{role}</li>)}</ul></Td>
                  <Td> <ul className="max-h-[200px] overflow-y-auto">{user.realmRoles.map(role=><li>{role}</li>)}</ul></Td>
                  <Td>{user.enabled+""}</Td>

                </Tr>
              );
            })}
        </Tbody>
      </Table>
  </TableContainer>
    </div>
    
}