import { Box, Button, Skeleton, Wrap } from "@chakra-ui/react";
import { resetTopology } from "../reducers/topologyReducer";
import { ResponseType, topologyApi } from "../services/topology.service";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Showforpermission } from "../components/Showforpermission";
import {
  getConnectedUserId,
  getLoadingDataErrorByStatus,
  installerRoles,
} from "../utils/toolbox";
import { useNavigate } from "react-router-dom";
import { SmallAddIcon } from "@chakra-ui/icons";
import Site from "../components/Site";
import EmsAlert from "../components/EmsAlert";
import { store } from "../store/config";
import { selectAuth } from "../reducers/authReducer";
interface MyToposPageProps {
  listTopos: ResponseType[];
}
export const MyToposPage: React.FC<MyToposPageProps> = ({ listTopos }) => {
  const navigate = useNavigate();
  return (
    <Box className="list-site">
      <div className="flex flex-row items-baseline content-center gap-4 py-4">
        <h1 className="list-site-title font-extrabold text-xl my-auto pl-8 md:px-2 ">
          Veuillez choisir un site
        </h1>
        {
          <Showforpermission allowedRoles={[...installerRoles()]}>
            <Button
              colorScheme="blue"
              w={16}
              className="my-auto"
              onClick={() => navigate("/flux-topology")}
            >
              <SmallAddIcon boxSize={6} />
            </Button>
          </Showforpermission>
        }
      </div>
      <div className="p-2 grid grid-cols-1 sm:grid-cols-2 gap-2 md:grid-cols-3 md:gap-3 lg:grid-cols-5 2xl:grid-cols-6 2xl:gap-5  pb-36">
        {listTopos ? (
          listTopos.length == 0 ? (
            <div>Vous n&apos;avez pas de site !</div>
          ) : (
            listTopos.map((topo, index) => (
              <>
                <Site
                  key={index}
                  id={topo.id + ""}
                  title={topo.name + ""}
                  address={topo.adresse + ""}
                  batteryCapacity={
                    topo.capacity ? (topo.capacity as string) : undefined
                  }
                  value={
                    <div className="flex flex-row justify-start gap-4 w-full">
                      {topo.pvtotalpower && topo.capacity ? (
                        <>
                          <div> Producteur : {topo.pvtotalpower as string}</div>{" "}
                          <div> Batterie : {topo.capacity as string}</div>
                        </>
                      ) : topo.pvtotalpower ? (
                        "Producteur : " + topo.pvtotalpower
                      ) : topo.pvtotalpower ? (
                        "Battrie : " + topo.capacity
                      ) : (
                        ""
                      )}
                    </div>
                  }
                  image={topo.img + ""}
                />
              </>
            ))
          )
        ) : (
          <></>
        )}
      </div>
    </Box>
  );
};
