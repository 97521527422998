import { selectAuth } from "../reducers/authReducer";
import {  isAuthorized } from "../utils/toolbox";
import React, { ReactNode } from "react";
import { store } from "../store/config";
import { Navigate } from "react-router-dom";

interface ShowforpermissionProps {
  allowedRoles: string[];
  children: ReactNode;
}
export const Showforpermission : React.FC<ShowforpermissionProps> = ({
  children,
  allowedRoles,
}) => {
  const auth = selectAuth(store.getState())
  return isAuthorized(allowedRoles,auth?.user?.token || "") ?  <>{children} </>  :<></>;
};
